import { configureStore } from '@reduxjs/toolkit'
import ecommerceReducer  from './Products/productSlice'
import cropsReducer from "./cropsReducer";

export const store = configureStore({
  reducer: {
      ecommerce: ecommerceReducer,
      crops: cropsReducer
  },
})
