import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./Loader";
const Products = React.lazy(() => import('./Products/products'));
const ProductsOrderSuccess = React.lazy(() => import('./Products/ProductsOrderSuccess'));

const MainApp = () => {
  return (
    <Routes>
      <Route path="products">
        <Route path=":unique_code" element={<Suspense fallback={<Loader />}> <Products /></Suspense>} />
        <Route path="" element={<Suspense fallback={<Loader />}> <Products /></Suspense>} />
      </Route>
      <Route path="success-order" element={<Suspense> <ProductsOrderSuccess /></Suspense>} />
      
    </Routes>
  );
};

export default MainApp;
