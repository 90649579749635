import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { toast } from 'react-toastify';
const customId = "custom-id-yes";

const initialState = {
  itemCount:0,
  creditCount:0,
  products:{},
  spinner:false,
  cartArray:[],
  defaultPacking:{

  },
  productSelectedInCategory:{
  },
  productsInCartMap:{},
  totalAmount:0,
  totalWholesale:0,
  totalRetailer:0,
  totalItemCount:0,
  totalWholesaleWithoutGst:0,
  totalRetailerWithoutGst: 0,
  acceptOrder: 0,
  paymentGateway: undefined
}
const roundTo2Digits = (num) => Math.round(num * 100) / 100

export const util = (postBody,token) => {

  if (!navigator.onLine) {
    showErroToast("Please check your Internet connection and try again.")
    return
  }

  let abortController = new AbortController();
  const timeout = setTimeout(() => {
    abortController.abort();
  }, 20000);
  axios
    .patch(
      "https://agcare.platform.simplifii.com/api/v1/cards",
      postBody,
      {
        headers: { Authorization: "Bearer " + token },
        signal: abortController.signal,
      }
    )
    .then((data) => {
      clearTimeout(timeout);
    })
    .catch((error) => {
      clearTimeout(timeout);
    });
}


export const ecommerceSlice = createSlice({
  name: 'ecommerce',
  initialState,
  reducers: {
    setdefaultPacking: (state, action) => {
      state.defaultPacking = action.payload.defaultPacking
    },
    setSpinner: (state,action)=>{
      state.spinner = action.payload.state
    },
    addToCard: (state,action) => {
      let packing = action.payload.packing;
      let userRole = state.role;
      state.products[action.payload.SKU] = action.payload.count ?? 1
      state.cartArray.push({
        retail_price:packing.retail_price,
        wholesale_price:packing.wholesale_price,
        wholesale_order_in_multiple_of:packing.wholesale_order_in_multiple_of,
        mrp:packing.mrp,
        productID:action.payload.product.id,
        technical_name:action.payload.product.technical_name,
        productName:action.payload.product.title,
        productImage:action.payload.product.cdata?.primary_image_thumbnail,
        sku:action.payload.SKU,
        qty:action.payload.count ?? 1,
        packingTitle: action.payload.packingTitle ? action.payload.packingTitle : `${((userRole == "Customer" || userRole == "Retailer" || userRole == "OrgAdmin") ? packing["wholesale_order_in_multiple_of"] : 1)} X ${packing["qty"]}${packing["UoM"]?packing["UoM"].toUpperCase():''} ${packing["packing_type"]}`,
        category: action.payload.category,
        gst_rate: action.payload.product.gst_rate ?? 0
      })
    },
    increaseDecreaseCount: (state,action) => {
      if(action.payload.intent == "Increase"){
        if(action.payload.count != null){
          state.products[action.payload.SKU] = action.payload.count
        }else{
          let delta = 1
          if(action.payload.delta) delta = action.payload.delta
          state.products[action.payload.SKU] = state.products[action.payload.SKU] + delta
        }
      }else{
        let delta = 1
        if(action.payload.delta) delta = action.payload.delta
        state.products[action.payload.SKU] = state.products[action.payload.SKU] - delta
      }
      for(let i = 0;i<state.cartArray.length;i++){
        let packing = state.cartArray[i]
        if(packing['sku'] == action.payload.SKU){
          if(state.products[action.payload.SKU] == "" || state.products[action.payload.SKU] == 0){
            state.cartArray.splice(i,1)
          }else{
            state.cartArray[i]['qty'] = state.products[action.payload.SKU]
            break
          }
        }
      }
    },
    computeTotal: (state,action)=>{
      state.totalAmount =  0;
      state.totalWholesale =  0;
      state.totalRetailer =  0;
      state.totalRetailerWithoutGst = 0;
      state.totalWholesaleWithoutGst = 0;
      let countInCategory = {};
      let productsInCartMap = {};

      for(let i = 0;i<state.cartArray.length;i++){
        let packing = state.cartArray[i]
        // console.log({packing:JSON.parse(JSON.stringify(packing))})
        if(packing.qty || packing.qty > 0){
          if(!countInCategory[packing.category]) countInCategory[packing.category] = {}
          countInCategory[packing.category][packing.sku] = 1
          productsInCartMap[packing.productID] = 1
        }
        state.totalAmount += packing.qty * packing.mrp ?? 0;
        state.totalWholesaleWithoutGst += packing.qty * packing.wholesale_price;
        state.totalRetailerWithoutGst += packing.qty * packing.retail_price;                                                                                              
        state.totalWholesale += roundTo2Digits((packing.qty * packing.wholesale_price) * (1 + ((packing?.gst_rate || 0) / 100))) ?? 0;
        state.totalRetailer += packing.qty * packing.retail_price * (1 + ((packing.gst_rate || 0) / 100)) ?? 0;
      }

      let totalItemCount = 0
      Object.keys(countInCategory).forEach(key=>{
        countInCategory[key] = Object.keys(countInCategory[key]).length
        totalItemCount += countInCategory[key]
      })
      state.productSelectedInCategory = countInCategory
      state.totalItemCount = totalItemCount
      state.productsInCartMap = productsInCartMap
      if(action.payload && action.payload.skipAPI) return
      let urlSearch = new URLSearchParams(window.location.search)

      let cartArray = state.cartArray
      let result = []
      let productID = {}
      cartArray.forEach(packing=>{
        if(productID[packing['sku']] == null){
          result.push(packing)
          productID[packing['sku']] = result.length-1
        }else{
          result[productID[packing['sku']]]['qty'] += (packing['qty'] ?? 0)
        }
      })
      let apiObj = {
        cart: state.cartArray.filter(packing=>packing.qty > 0),
        action: "Cart",
        card_unique_code: urlSearch.get('uc')
      }
      util(apiObj,urlSearch.get('token'))
    },
    replace:(state,action)=>{
      state.cartArray = action.payload.cart
    },
    setCreditCount:(state,action)=>{
      state.creditCount = action.payload.creditCount
    },
    setSKUMAP: (state,action) => {
      state.SKUMAP = action.payload.SKUMAP
    },
    changeQtyAtIndex: (state,action) => {
      state.cartArray[action.payload.index]['qty'] += action.payload.change
    },
    setZone: (state,action)=>{
      state.zone = action.payload.zone
    },
    setRole: (state,action)=>{
      state.role = action.payload.role
    },
    setUserData: (state,action)=>{
      state.userdata = action.payload.userdata
    },
    setAcceptOrder: (state,action)=>{
      state.acceptOrder = action.payload
    },
    setPaymentGateway: (state,action)=>{
      state.paymentGateway = action.payload
    }

  },
})

// Action creators are generated for each case reducer function
export const { addToCard ,setCreditCount,increaseDecreaseCount,setSKUMAP,changeQtyAtIndex,computeTotal,setSpinner,setRole,setUserData,setAcceptOrder,setPaymentGateway, setZone,replace,setdefaultPacking} = ecommerceSlice.actions

export default ecommerceSlice.reducer

const showErroToast = (msg)=>{
  toast.error(msg || "Something went wrong.", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    toastId: customId,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}