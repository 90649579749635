import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    myCrops:[]
}

export const cropSlice = createSlice({
    name: 'crop',
    initialState,
    reducers: {
        setCrops: (state,action)=>{
            state.myCrops = action.payload
        }
    },
})

export const {
    setCrops
} = cropSlice.actions

export default cropSlice.reducer
