import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import MainApp from "./MainApp";
import { Provider } from 'react-redux'
import {store} from './Store';
import 'react-toastify/dist/ReactToastify.css';
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
  <BrowserRouter>
    <MainApp />
  </BrowserRouter>
  </Provider>
);
